import React from "react"
import { Flex, Heading, Text, Image } from "@chakra-ui/react"
import { ChevronDownIcon } from "@chakra-ui/icons"

const styles = {
  root: {
    background: { base: "#E8EBFE", md: "#E8EBFE", lg: "transparent" },
    flexDirection: { base: "column-reverse", md: "column-reverse", lg: "row" },
    alignItems: "center",
    pt: { base: "40px", md: "40px", lg: "80px" },
    width: "100%",
    px: { base: "0px", md: "0px", lg: "10%" },
    justifyContent: "center",
  },
  "left-box": {
    flexDirection: "column",
    justifyContent: "center",
    width: "256px",
    px: { base: "24px", md: "24px", lg: "0px" },
    alignItems: { base: "center", md: "center", lg: "flex-start" },
  },
  heading: {
    fontWeight: "bold",
    color: "#1B263F",
    fontSize: { base: "24px", md: "24px", lg: "48px" },
    lineHeight: { base: "32px", md: "32px", lg: "56px" },
    paddingTop: { base: "2.5em", md: "0em", lg: "0em" },
  },
  text: {
    fontSize: { base: "16px", md: "15px", lg: "15px" },
    lineHeight: "150%",
    letterSpacing: "0.0015em",
    color: "#1B263F",
    marginTop: { base: "16px", md: "16px", lg: "24px" },
    textAlign: { base: "center", md: "center", lg: "left" },
  },
  image: {
    height: { base: "auto", md: "auto", lg: "350px" },
    width: { base: "100%", md: "100%", lg: "658px" },
    ml: { base: "0px", md: "0px", lg: "26px" },
  },
  icon: {
    display: { base: "flex", md: "flex", lg: "none" },
    color: "#9CACBC",
    fontSize: "42px",
    marginTop: "32px",
  },
}

const Hero = ({ image, title, content }) => (
  <Flex sx={styles.root}>
    <ChevronDownIcon sx={styles.icon} />
    <Flex sx={styles["left-box"]}>
      <Heading sx={styles.heading}>{title}</Heading>
      <Text sx={styles.text}>{content}</Text>
    </Flex>
    {image && <Image src={image} alt={title} sx={styles.image} />}
  </Flex>
)

export default Hero
