import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import TeamMember from "../components/our-team/team-member"
import ReadShortBio from "../components/our-team/short-bio"
import Hero from "../components/our-team/hero"
import OurTeamImage from "../images/our-team/our-team-image.png"
import { Grid, GridItem } from "@chakra-ui/react"

const getPrevious = (people, index) =>
  index > 0 ? people[index - 1] : people[people?.length - 1]

const getNext = (people, index) =>
  index < people?.length - 1 ? people[index + 1] : people[0]

const OurTeamIndex = props => {
  const people = props.data.allContentfulPerson.nodes.map((person, index) => ({
    ...person,
    previous: getPrevious(props.data.allContentfulPerson.nodes, index),
    next: getNext(props.data.allContentfulPerson.nodes, index),
    index,
  }))

  const [person, setPerson] = React.useState()

  const handleClickPerson = payload => {
    setPerson(payload)
  }

  const handleClose = () => {
    setPerson(undefined)
  }

  const handlePrevious = React.useCallback(
    payload => {
      const current = getPrevious(people, payload.index)
      const previous = getPrevious(people, current.index)
      const next = getNext(people, current.index)
      setPerson({
        ...current,
        previous,
        next,
      })
    },
    [people]
  )

  const handleNext = React.useCallback(
    payload => {
      const current = getNext(people, payload.index)
      const previous = getPrevious(people, current.index)
      const next = getNext(people, current.index)
      setPerson({
        ...current,
        previous,
        next,
      })
    },
    [people]
  )

  return (
    <Layout location={props.location} title={"Our Team"}>
      <Hero
        title="Our Team"
        content="We are a business of leaders, engineers, physicians, academics and doers. Our mission is to fundamentally improve the value of health care. "
        image={OurTeamImage}
      />
      <Grid
        justifyItems="center"
        templateColumns={{
          base: "repeat(1, 1fr)",
          md: "repeat(2, 1fr)",
          lg: "repeat(3, 1fr)",
        }}
        gap={4}
        width={{ base: "100%", md: "100%", lg: "996px" }}
        margin="0 auto"
        px={{ base: "24px", md: "24px", lg: "40px" }}
        marginBottom="80px"
      >
        {people.map(person => (
          <GridItem rowSpan={2}>
            <TeamMember
              {...person}
              key={person.id}
              onClick={handleClickPerson}
            />
          </GridItem>
        ))}
      </Grid>
      <ReadShortBio
        isOpen={!!person}
        person={person}
        onClose={handleClose}
        onPrevious={handlePrevious}
        onNext={handleNext}
      />
    </Layout>
  )
}

export default OurTeamIndex

export const query = graphql`
  query OurTeamIndexQuery {
    allContentfulPerson(
      sort: { fields: [name], order: ASC }
      filter: { hidden: { ne: true } }
    ) {
      nodes {
        id
        image {
          gatsbyImageData(placeholder: BLURRED, width: 425, height: 420)
          children {
            children {
              id
            }
          }
          id
        }
        name
        title
        shortBio {
          childMarkdownRemark {
            html
          }
        }
      }
    }
  }
`
