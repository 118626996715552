import React from "react"
import { Box, chakra, Image, Text } from "@chakra-ui/react"
import { ChevronDownIcon } from "@chakra-ui/icons"
import { GatsbyImage } from "gatsby-plugin-image"

const styles = {
  box: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    marginTop: "5em",
  },
  button: {
    display: "block",
    width: "auto",
    outline: "none",
    bg: "transparent",
    padding: "0px",
    cursor: "pointer",
  },
  text: {
    color: "#129459",
    fontWeight: "bold",
    size: "md",
    marginTop: "1em",
  },
}

const TeamMember = props => {
  return (
    <Box sx={styles.box}>
      <Image
        as={GatsbyImage}
        alt={props?.name}
        image={props?.image?.gatsbyImageData}
      />
      <Text sx={styles.text}>{props?.name}</Text>
      <Text fontSize="sm">{props?.title}</Text>
      <chakra.div sx={styles.button} onClick={() => props?.onClick(props)}>
        <Text fontSize="sm" marginTop="1em" color="#3399FF">
          read more about {props?.name}
          <ChevronDownIcon boxSize="1.5em" marginLeft=".3em" />
        </Text>
      </chakra.div>
    </Box>
  )
}

export default TeamMember
